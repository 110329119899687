import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";

const Location = (props) => {
  const { data } = props;
  if (!data) return null;
  const { page } = data;

  return (
    <div style={{ overflow: "hidden" }}>
      <Seo page={page} />
      <Layout>
        <div className="row flex-column flex-xl-row">
          <div className="description-location col-10 col-xl-4 order-2 order-xl-1 mx-auto mx-lg-0">
            <div className="custom-margin mt-1">
              <PrismicRichText
                field={page.data.location_description.richText}
                className="text-white"
              />
            </div>
          </div>
          <div className="col-12 col-xl-8 order-1 order-xl-2">
            <iframe
              src={page.data.map_url}
              className="map"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query locationQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicLocation {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        map_url
        location_description {
          richText
        }
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
      }
    }
  }
`;

export default Location;
